///////////////////////////////////////////////////////////
// Breadcrumbs ////////////////////////////////////////////
///////////////////////////////////////////////////////////

.tooltime-breadcrumbs {
  .breadcrumb-item,
  .breadcrumb-item:not([href]),
  .breadcrumb-item:not([href]):hover,
  .breadcrumb-item:hover {
    color: $gray-light;
  }
}

.aor-field:first-child img {
  width: 60px;
}

.aor-field:nth-of-type(2) {
  width: 310px;
}

.aor-field:nth-of-type(3) {
  width: 225px;
}

.aor-field:nth-of-type(4) {
  width: 130px;
}

.aor-field label {
  width: 110px;
}

.aor-field {
  display: inline-block;
  margin-right: 30px;
}

.aor-field span {
  display: inline-block;
}

.product-image-wrapper {
  align-items: center;
  background-color: #f2f2f2;
  cursor: grab;
  display: flex;
  margin: 14px 0;
}

.product-image {
  border: 1px solid #000;
  display: inline-block;
  margin-right: 30px;
  width: 100px;
}

.image-position {
  display: inline-block;
  margin-right: 400px;
}

.image-id {
  display: inline-block;
}

///////////////////////////////////////////////////////////
// Tables /////////////////////////////////////////////////
///////////////////////////////////////////////////////////

.tooltime-table {
  background-color: transparent;
  font-family: $font-family-neuzeit-office-sr-pro-bold;
  font-size: 1rem;

  tr {
    td {
      border-top-color: $gray-lighter;
    }

    &:last-child {
      td {
        border-bottom: 1px solid $gray-lighter;
      }
    }
  }

  td {
    padding-top: .95rem;
    position: relative;
  }

  td:nth-last-child(-n+2) {
    text-align: right;

    span {
      right: 10px;
    }
  }

  .label {
    bottom: 3px;
    color: $gray-lighter;
    display: block;
    font-size: $font-size-xxs;
    position: absolute;
    text-transform: uppercase;
  }

  &.simple-table {
    td:first-child {
      font-size: $font-size-md;
    }
  }

  &.simple-table-image {
    td:first-child {
      border-left: 1px solid $gray-lighter;
      padding: 0;
      width: 41px;
    }

    img {
      height: 55px;
    }

    td:nth-child(2) {
      font-size: $font-size-md;
    }
  }
}

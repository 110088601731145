///////////////////////////////////////////////////////////
// ToolTime-Drawers ///////////////////////////////////////
///////////////////////////////////////////////////////////

.tooltime-drawers {
  border: 1px solid $faint-grey;
  padding: 10px 0;

  .tooltime-drawers {
    border: 0;
  }

  &.is--drawer-open {
    background-color: $white-smoke;
  }
}

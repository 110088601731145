@import 'includes';

@import 'objects/frame';
@import 'objects/media';
@import 'objects/stack';
@import 'sheets/search';

.tooltime-container {
  margin-top: 70px;
}

#root,
#root > div {
  height: 100%;
}

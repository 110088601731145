////
/// Frame Object
///
/// Structures a frame object for lazy loading various media (images, video)
////

/// Frame
///
/// The containing frame object block
.o-frame {
  background-color: $color-grey-3;
  display: block;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
}

/// Frame Canvas
///
/// The "canvas" that sits behind the media and expands it to its parent container
.o-frame__canvas {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/// Frame Media
///
/// The actual media that will be displayed within the frame
.o-frame__media,
.o-frame__canvas img {
  display: block;
  width: 100%;
}

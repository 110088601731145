///////////////////////////////////////////////////////////
// Buttons ////////////////////////////////////////////////
///////////////////////////////////////////////////////////

// Base styles

.tooltime-button,
a.tooltime-button {
  cursor: pointer;
  font-family: $font-family-neuzeit-office-sr-pro-bold, $font-family-base;
  height: 55px;
  text-transform: uppercase;

  &.text-center {
    text-align: center;
  }

  &.text-left {
    text-align: left;
  }

  &.text-right {
    text-align: right;
  }

  &.btn-md {
    padding-top: .75rem;
  }

  &.btn-sm {
    height: 40px;
  }
}

a.tooltime-button {
  text-decoration: none;

  &.btn-md {
    padding-top: 1.05rem;
  }
}

.button-text-title {
  font-weight: 700;
}

.button-text-subtitle {
  color: $gray-lighter;
  display: block;
  font-size: $font-size-xs;
}

// Alternate buttons

.btn-success {
  @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);

  &:hover {
    background-color: $brand-success-hover;
    border-color: $brand-success-hover;
  }
}

.btn-khaki {
  @include button-variant($btn-decision-color, $btn-decision-bg, $btn-decision-border);

  .button-text-subtitle {
    color: $btn-decision-subtitle-color;
  }
}

.btn-theblacktux {
  @include button-variant($btn-decision-color, $btn-decision-bg, $btn-decision-border);

  .button-text-subtitle {
    color: $btn-decision-subtitle-color;
  }
}

.btn-dark {
  @include button-variant($btn-dark-color, $btn-dark-bg, $btn-dark-border);

  .button-text-subtitle {
    color: $btn-dark-subtitle-color;
  }
}

.btn-secondary {
  @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
}

.btn-secondary-dark {
  @include button-variant($btn-secondary-dark-color, $btn-secondary-dark-bg, $btn-secondary-dark-border);
}

.btn-icon {
  width: 55px;

  &.btn-md {
    font-size: $font-size-xxxlg;
    padding: 0;
  }

  .fa {
    vertical-align: middle;
  }
}

.btn-link {
  @include button-variant($brand-success, $transparent, $transparent);
  text-decoration: $link-decoration;

  @include hover-focus {
    box-shadow: none;
    color: $brand-success-hover;
  }
}

.btn-link-gray {
  @include button-variant($gray-light, $transparent, $transparent);
  text-decoration: $link-decoration;

  @include hover-focus {
    box-shadow: none;
    text-decoration: $link-hover-decoration;
  }
}

// Button Sizes

.btn-md {
  @include button-size($btn-padding-y-md, $btn-padding-x-md, $font-size-md, $btn-border-radius-md);
}

///////////////////////////////////////////////////////////
// Fonts //////////////////////////////////////////////////
///////////////////////////////////////////////////////////

// Benton Sans Extra Compressed

@font-face {
  font-family: 'BentonSansComp-Book';
  font-style: normal;
  font-weight: normal;
  src: font-url('BentonSansComp-Book.eot?#iefix') format('embedded-opentype'), font-url('BentonSansComp-Book.woff') format('woff'), font-url('BentonSansComp-Book.ttf')  format('truetype'), font-url('BentonSansComp-Book.svg#BentonSansComp-Book') format('svg');
}

@font-face {
  font-family: 'BentonSansComp-Light';
  font-style: normal;
  font-weight: normal;
  src: font-url('BentonSansComp-Light.eot?#iefix') format('embedded-opentype'), font-url('BentonSansComp-Light.woff') format('woff'), font-url('BentonSansComp-Light.ttf')  format('truetype'), font-url('BentonSansComp-Light.svg#BentonSansComp-Light') format('svg');
}

@font-face {
  font-family: 'BentonSansExtraComp-Light';
  font-style: normal;
  font-weight: normal;
  src: font-url('BentonSansExtraComp-Light.eot?#iefix') format('embedded-opentype'), font-url('BentonSansExtraComp-Light.woff') format('woff'), font-url('BentonSansExtraComp-Light.ttf')  format('truetype'), font-url('BentonSansExtraComp-Light.svg#BentonSansExtraComp-Light') format('svg');
}

// Neuzeit Office Soft Rounded Pro

@font-face {
  font-family: 'NeuzeitOfficeSRPro-Bold';
  font-style: normal;
  font-weight: normal;
  src: font-url('NeuzeitOfficeSRPro-Bold.eot?#iefix') format('embedded-opentype'), font-url('NeuzeitOfficeSRPro-Bold.woff') format('woff'), font-url('NeuzeitOfficeSRPro-Bold.ttf') format('truetype'), font-url('NeuzeitOfficeSRPro-Bold.svg#NeuzeitOfficeSRPro-Bold') format('svg');
}

// Sentinel

@font-face {
  font-family: 'Sentinel-Book';
  font-style: normal;
  font-weight: normal;
  src: font-url('Sentinel-Book.eot?#iefix') format('embedded-opentype'), font-url('Sentinel-Book.woff') format('woff'), font-url('Sentinel-Book.ttf')  format('truetype'), font-url('Sentinel-Book.svg#Sentinel-Book') format('svg');
}

@font-face {
  font-family: 'Sentinel-BookItalic';
  font-style: normal;
  font-weight: normal;
  src: font-url('Sentinel-BookItalic.eot?#iefix') format('embedded-opentype'), font-url('Sentinel-BookItalic.woff') format('woff'), font-url('Sentinel-BookItalic.ttf')  format('truetype'), font-url('Sentinel-BookItalic.svg#Sentinel-BookItalic') format('svg');
}

@font-face {
  font-family: 'Sentinel-Light';
  font-style: normal;
  font-weight: normal;
  src: font-url('Sentinel-Light.eot?#iefix') format('embedded-opentype'), font-url('Sentinel-Light.woff') format('woff'), font-url('Sentinel-Light.ttf')  format('truetype'), font-url('Sentinel-Light.svg#Sentinel-Light') format('svg');
}

@font-face {
  font-family: 'Sentinel-LightItalic';
  font-style: normal;
  font-weight: normal;
  src: font-url('Sentinel-LightItalic.eot?#iefix') format('embedded-opentype'), font-url('Sentinel-LightItalic.woff') format('woff'), font-url('Sentinel-LightItalic.ttf')  format('truetype'), font-url('Sentinel-LightItalic.svg#Sentinel-LightItalic') format('svg');
}

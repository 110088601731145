.hit {
  padding: 16px;

  &__header {
    font-weight: 600;
    margin-bottom: 0;
  }

  &__link,
  &__row-item,
  &__title-link {
    color: #000;
  }

  &__link:hover,
  &__row-item:hover,
  &__title-link:hover {
    color: rgba(20, 21, 21, 0.65);
  }

  &__link:visited,
  &__row-item:visited,
  &__title-link:visited {
    color: rgba(20, 21, 21, 0.65);
  }

  &__secondary-title {
    margin-left: 48px;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
  }

  &__row-item:not(:first-child) {
    margin-left: 36px;
  }

  &__row-item--strong {
    font-weight: bold;
  }

  &__row-item--link:hover {
    color: rgba(20, 21, 21, 0.65);
  }

  &__row-item--link:visited {
    color: rgba(20, 21, 21, 0.65);
  }
}

/* stylelint-disable selector-class-pattern */
.ais-Highlight-highlighted {
  padding: 0;
}
/* stylelint-disable */

///////////////////////////////////////////////////////////
// Sizes //////////////////////////////////////////////////
///////////////////////////////////////////////////////////

// sp = "sizes page"
.sp-label {
  font-family: $font-family-neuzeit-office-sr-pro-bold;
  font-size: $font-size-xs;
  padding-top: 12px;
  text-transform: uppercase;
}

.sp-category-container {
  border-top: 1px solid $gray-lighter;
  margin-left: 0;
  margin-right: 0;
  padding: 15px 30px 5px;

  &:last-of-type {
    padding-bottom: 13px;
  }
}

.sp-submit-survey-info {
  font-style: italic;
  line-height: 1.3em;
}

.sp-audit-fields {
  font-size: $font-size-xs;
  padding-bottom: 10px;
  padding-right: 10px;
}

.sp-form-row {
  margin-bottom: 7px;
}

.sp-column-headers-container {
  padding-right: 10px;
}

.sp-prediction {
  cursor: pointer;
}

.fa.sp-chevron-right-sm {
  color: $gray-lighter;
  font-size: $font-size-xxs;
  padding-left: 5px;
}

.sp-size-checked-badge {
  display: inline-block;
  font-size: $font-size-xs;
  padding-top: 8px;
}

.sp-display-field {
  line-height: 2.4em;
  padding-left: 11px;
}

.sp-form-field {
  width: 50%;

  &.read-only {
    -webkit-appearance: none;
    background: none;
    border: 0;
    color: $gray-dark;
    cursor: default;
  }
}

// Overriding 50% width for below form fields
.height .form-control {
  max-width: none;
  width: 115%;
}

.weight .form-control {
  width: 115%;
}

.birth-year .form-control {
  width: 115%;
}

.reason {
  width: 200%;
}

// Creates the verical gray box ////
.sp-form-container {
  border: 1px solid $gray-lighter;
  position: relative;

  &::after {
    background: $gray-lightest;
    bottom: 0;
    content: '';
    left: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 28%;
    z-index: -1;
  }
}
// End creating vertical gray box ////

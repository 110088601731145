////
/// Media Object
///
/// Structures an undecorated media object
////

/// Media
///
/// The containing media object block
.o-media {
  align-items: flex-start;
  display: flex;
}

/// Modifier class for vertically centering content
.o-media--centered {
  align-items: center;
}

/// Media Figure
///
/// Used for an image or icon next to the body.
.o-media__figure {
  flex-shrink: 0;
}

/// Media Body
///
/// Contains the text body of the media object. Expands to fill the rest of the
/// available horizontal space.
.o-media__body {
  flex-grow: 1;
}

///////////////////////////////////////////////////////////
// Nav ////////////////////////////////////////////////////
///////////////////////////////////////////////////////////

.nav-stacked {
  .nav-item {

    &:hover:nth-child(n+2):nth-last-child(n+2) {
      background-color: $gray-dark;
    }
  }

  .nav-stacked {
    background-color: $gray-lighter;

    .nav-link {
      color: $black;
      font-size: $font-size-sm;
      height: 2.5rem;
      padding-left: 1.7rem;
    }

    .nav-link:hover {
      color: $white;
    }
  }
}

// ToolTime Tabs

.tooltime-tabs {
  border-bottom-width: 0;

  .nav-item {
    display: table;
    height: 70px;
    margin-bottom: 0;
  }

  .nav-link {
    border-radius: 0;
    border-width: 0;
    color: $navbar-dark-color;
    display: table-cell;
    font-family: $font-family-neuzeit-office-sr-pro-bold;
    font-size: $font-size-xs;
    line-height: 1.2;
    padding: 0 2rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;

    &.active,
    &.active:focus,
    &.active:hover {
      color: $navbar-dark-active-color;
    }
  }

  .btn-icon {
    font-size: $font-size-xxxlg;
    line-height: 1;
    padding-left: 0;
    padding-right: 0;
    width: 70px;
  }

  &.dark-theme {
    background-color: $navbar-dark-color-bg;

    .nav-item {
      background-color: $nav-tabs-bg-color;
    }
  }

  &.light-theme {
    .nav-link {
      color: $black;
      font-size: $font-size-lg;

      &.active {
        text-decoration: underline;
      }
    }
  }
}

// ToolTime Nav Stacked

.nav-stacked-tooltime {
  background-color: $black;
  border-radius: 0;
  bottom: 0;
  color: $white;
  font-family: $font-family-neuzeit-office-sr-pro-bold;
  height: 100%;
  left: -350px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 0 40px;
  position: fixed;
  text-transform: uppercase;
  transition: all .5s ease;
  width: 350px;
  z-index: $zindex-site-menu;

  @include not-desktop {
    left: -320px;
    width: 320px;
  }

  .tooltime-button {
    @include size(70px);
    &:focus {
      box-shadow: none;
    }
  }

  .nav-link {
    color: $white;
    display: inline-block;
    font-size: $font-size-base;
    padding: .75rem .25rem .75rem 1rem;
    text-decoration: none;
    width: 100%;

    &.active {
      background-color: $gray-lightest;
      color: $black;
    }

    &:hover:not(.active) {
      background-color: $white;
      color: $black;
    }
  }

  .nav-item-header {
    font-family: $font-family-sentinel-light;
    height: 70px;
    position: absolute;
    width: 100%;

    .nav-link {
      font-size: 1.25rem;
      margin-right: 10px;
      padding: 0;
      position: relative;
      width: 50px;

      &:hover {
        background-color: inherit;
        color: inherit;
      }
    }
  }

  .nav-item-body {
    margin-top: 70px;
    overflow: scroll;
  }

  .nav-item-parent {
    border-bottom: 1px solid rgba($gray-lightest, .3);

    .nav-link {
      font-size: $font-size-lg;
      padding: 1.2rem .25rem 1rem 1rem;

      &:hover {
        background-color: inherit;
        color: inherit;
      }
    }
  }

  .nav-item-child {
    background-color: $gray-light;

    .nav-item-child {
      .nav-link {
        background-color: $white;
        color: $black;
        padding-left: 3.125rem;
      }
    }
  }

  .is--nav-item-open {
    .nav-link:hover {
      background-color: $gray-lightest;
    }

    > .nav-link:hover {
      background-color: inherit;
      color: inherit;
      cursor: pointer;
    }

    .nav-link.active {
      background-color: $gray-lightest;
    }
  }

  .nav-item-logout {
    bottom: 0;
    position: absolute;
    width: 100%;
  }

  &.is--site-menu-open {
    left: 0;
  }
}

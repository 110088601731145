.search-page {
  font-family: Roboto, sans-serif;

  &__content {
    margin: 0 auto;
    padding: 10px 24px 84px;
  }

  &__input {
    input {
      background-color: #fff;
      border: 0 !important;
      border-bottom: 1px solid #000 !important;
      margin-bottom: 10px;
    }
  }

  &__hit-section {
    padding: 20px 0;
  }

  &__filter-menu {
    padding: 0 8px;
  }
}

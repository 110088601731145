///////////////////////////////////////////////////////////
// Reboot /////////////////////////////////////////////////
///////////////////////////////////////////////////////////

html,
body {
  height: 100%;
  min-height: 100%;
}

// Reset HTML, body, and more

body {
  // Make the `body` use the `font-size-root`
  font-family: $font-family-base;
}

// Links

a {
  color: $link-color;
  text-decoration: $link-decoration;

  @include hover-focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus {
    @include plain-hover-focus();
  }
}

///////////////////////////////////////////////////////////
// SVG ////////////////////////////////////////////////////
///////////////////////////////////////////////////////////

// Base
// ________________________________________________________

@mixin svg {
  > svg {
    height: auto;
    max-height: 100%;
    width: 100%;
  }
}

@mixin svg-fill {
  > svg { fill: currentColor; }
}

@mixin svg-center {
  overflow: hidden;
  position: relative;

  > svg {

    @include absolute-center;
    height: 100%;
    max-height: none;
    width: 100%;
  }
}

@mixin svg-fill-center {
  @include svg;
  @include svg-fill;
  @include svg-center;
}

.svg-fill-center {
  @include svg-fill-center;
}

/* stylelint-disable selector-class-pattern */
.ais-Hits-list {
  list-style-type: none;
  padding: 0;
}
/* stylelint-disable */

.ais-Hits-item {
  padding-top: 8px;
}

.hit-list {
  padding: 8px;

  &__header-container {
    align-items: center;
    border-bottom: 3px solid #b8b8b8;
    display: flex;
    justify-content: space-between;
  }

  &__header {
    color: #b8b8b8;
    font-weight: bold;
    margin: 0;
    padding: 8px 8px 8px 0;
    text-transform: uppercase;
  }

  &__header-results {
    font-weight: bold;
  }

  &__no-results {
    left: 50%;
    padding-top: 24px;
    position: absolute;
    transform: translateX(-50%);
  }
}

.search-filter {
  &__list {
    margin-bottom: 20px;
  }
}

.date-input {
  input {
    background-color: #fff;
    border: 0;
    border-bottom: 1px solid #000;
    color: #424242;
    font-size: 0.9rem;
    height: 42px;
    outline: none;
    padding: $space-8 $space-12;
    width: 100%;
  }
}

.input-container {
  color: #999 !important;
  font-size: $text-18 !important;
  div {
    border: 0;
  }
}

.list-container {
  max-height: unset !important;
}

.alert {
  border-radius: 0;
}

// ToolTime Notification

.tooltime-notification {
  display: none;
  margin-bottom: 0;

  // Since the app bar is position: fixed, we need to add margin to this
  // notification to properly display the notification beneath the app bar
  margin-top: 70px;
  position: relative;

  &.is--notification-open {
    display: block;
  }

  .tooltime-button {
    height: 30px;
    line-height: 1;
    position: absolute;
    right: 0;
    top: 10px;
  }

  svg {
    height: 20px;
  }
}

// Alternate styles

.alert-confirmation {
  @include alert-variant($alert-confirmation-bg, $alert-confirmation-border, $alert-confirmation-text);
}

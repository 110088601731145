///////////////////////////////////////////////////////////
// Card ///////////////////////////////////////////////////
///////////////////////////////////////////////////////////

.tooltime-card {
  border-color: $gray-lighter;
  border-radius: 0;
  font-family: $font-family-neuzeit-office-sr-pro-bold;
  margin-bottom: 1rem;

  .card-header {
    background-color: $gray-lightest;
    padding: .907rem .75rem;
    position: relative;
  }

  .card-block {
    border-radius: 0;
    padding: 0;

    .row {
      @include no-gutters;
    }
  }
}

// User Event Card

.user-event-card {
  .row {
    border-top: 1px solid $gray-lighter;
    color: $black;
    text-decoration: none;

    &:hover {
      color: $black;
    }

    &:first-child {
      border-top: 0;
    }
  }

  .list-group-item {
    border: 0;
    border-top: 1px solid $gray-lighter;

    &:first-child {
      border-top: 0;
    }
  }
}

.list-group-item {
  padding-bottom: .907rem;
  padding-top: .907rem;
}

.btn-icon-column {
  width: 55px;
}

.user-event-card-cancelled {
  .strike {
    text-decoration: line-through;
  }
}

.user-event-card-toc {
  padding: .907rem 1.25rem;
}

// User Event Participants

.user-event-participants {
  div:first-child {
    position: relative;
  }

  .user-event-name {
    @include absolute-center-vert;
  }
}

// User Participants Card

.user-event-participant-card {

  .user-event-participant-card-name {
    @include absolute-center-vert;
  }

  .simple-table-image {
    tr:first-child {
      td {
        border-top-width: 0;
      }
    }

    tr:last-child {
      td {
        border-bottom-width: 0;
      }
    }
  }
}

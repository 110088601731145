////
/// Stack
///
/// Injects margin between elements from their common parent
///
/// @see [Every Layout](https://every-layout.dev/layouts/stack/)
////

.o-stack {
  > * + * {
    margin-top: $space-16;
  }

  &--small {
    > * + * {
      margin-top: $space-8;
    }
  }

  &--large {
    > * + * {
      margin-top: $space-32;
    }
  }

  &--x-large {
    > * + * {
      margin-top: $space-64;
    }
  }
}

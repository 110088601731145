.material-pagination {
  align-items: center;
  display: flex;
  justify-content: center;

  &__list {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0;
  }

  &__item {
    display: inline-block;
  }
}

///////////////////////////////////////////////////////////
// Mixins /////////////////////////////////////////////////
///////////////////////////////////////////////////////////

// Positioning
// ________________________________________________________

// -- Absolute

@mixin absolute($top, $right: $top, $bottom: $top, $left: $right) {
  bottom: $bottom;
  left: $left;
  position: absolute;
  right: $right;
  top: $top;
}

@mixin absolute-center {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

@mixin absolute-center-horiz {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

@mixin absolute-center-vert {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin absolute-left-center-right {
  position: relative;

  & > .left,
  & > .center,
  & > .right { position: absolute; }

  & > .left {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  & > .center {
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  & > .right {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

@mixin no-gutters {
  margin-left: 0;
  margin-right: 0;

  > [class^='col-'],
  > [class*=' col-'] {
    padding-left: 0;
    padding-right: 0;
  }
}

// Media Queries
// ________________________________________________________

@mixin range($min-width, $max-width) { @media (min-width: $min-width) and (max-width: $max-width) { @content; } }
@mixin from($min-width) { @media (min-width: $min-width) { @content; } }
@mixin to($max-width) { @media (max-width: $max-width) { @content; } }

@mixin phone { @include to($phone-end) { @content; } }
@mixin tablet { @include range($tablet-start, $tablet-end) { @content; } }
@mixin desktop { @include from($desktop-start) { @content; } }
@mixin not-phone { @include from($tablet-start) { @content; } }
@mixin not-tablet { @media (max-width: $phone-end), (min-width: $desktop-start) { @content; } }
@mixin not-desktop { @include to($tablet-end) { @content; } }

@mixin small-phone { @include to($small-phone-end) { @content; } }
@mixin small-device { @include to($small-tablet-end) { @content; } }
@mixin medium-phone { @include range($medium-phone-start, $medium-phone-end) { @content; } }
@mixin large-phone { @include range($large-phone-start, $large-phone-end) { @content; } }
@mixin small-tablet { @include range($small-tablet-start, $small-tablet-end) { @content; } }
@mixin medium-tablet { @include range($medium-tablet-start, $medium-tablet-end) { @content; } }
@mixin small-desktop { @include range($small-desktop-start, $small-desktop-end) { @content; } }
@mixin medium-desktop { @include range($medium-desktop-start, $medium-desktop-end) { @content; } }
@mixin large-desktop { @include from($large-desktop-start) { @content; } }

@mixin from-medium-phone { @include from($medium-phone-start) { @content; } }
@mixin from-large-phone { @include from($large-phone-start) { @content; } }
@mixin from-small-tablet { @include from($small-tablet-start) { @content; } }
@mixin from-medium-tablet { @include from($medium-tablet-start) { @content; } }
@mixin from-small-desktop { @include from($small-desktop-start) { @content; } }
@mixin from-medium-desktop { @include from($medium-desktop-start) { @content; } }
@mixin from-large-desktop { @include from($large-desktop-start) { @content; } }

///////////////////////////////////////////////////////////
// Jumbotron //////////////////////////////////////////////
///////////////////////////////////////////////////////////

.tooltime-jumbotron {
  background-color: $transparent;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 0;
  margin-bottom: 4rem;

  @include phone {
    background-image: image-url('jumbotron-320.jpg');
    margin-bottom: 2rem;
  }

  @include tablet { background-image: image-url('jumbotron-600.jpg'); }
  @include from-small-desktop { position: relative; }
  @include small-desktop { background-image: image-url('jumbotron-1024.jpg'); }
  @include from-medium-desktop { background-image: image-url('jumbotron-1360.jpg'); }

  .tooltime-jumbotron-title {
    font-size: 3.5rem;
  }

  .tooltime-jumbotron-lead {
    color: $gray-light;
    font-size: $font-size-xlg;
  }
}

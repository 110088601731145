///////////////////////////////////////////////////////////
// Variables //////////////////////////////////////////////
///////////////////////////////////////////////////////////

// Colors

$black:                          #000;
$charcoal:                       #222;
$faint-grey:                     #e3e3e3;
$gray-dark:                      #333;
$gray:                           #454545;
$gray-charcoal:                  #999;
$gray-light:                     #666;
$gray-lighter:                   #ccc;
$gray-lightest:                  #f2f2f2;
$khaki:                          #ded8c5;
$transparent:                    transparent;
$navy-blue:                      #135c9f;
$sand:                           #efebdf;
$white-smoke:                    #f5f5f5;
$white:                          #fff;

$brand-decision:                 $khaki;
$brand-danger:                   #900;
$brand-primary:                  #00f;
$brand-success:                  #45c55f; // #008000;
$brand-success-hover:            #0c3;
$brand-inverse:                  $gray-light;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px
) !default;

// Functions

$font-url-path: '../../fonts/' !default;
$image-url-path: '../../images/' !default;
$svg-url-path: '../../svg/' !default;

// Links

$link-color:                     $brand-primary;
$link-decoration:                underline;
$link-hover-color:               darken($link-color, 15%) !default;
$link-hover-decoration:          underline !default;

// Code

$pre-bg:                         $gray-dark;
$pre-color:                      $gray-lighter;

// Typography

$font-family-benton-sans-comp-book: 'BentonSansComp-Book';
$font-family-benton-sans-comp-light: 'BentonSansComp-Light';
$font-family-benton-sans-extra-comp-light: 'BentonSansExtraComp-Light';

$font-family-neuzeit-office-sr-pro-bold: 'NeuzeitOfficeSRPro-Bold';

$font-family-sentinel-book: 'Sentinel-Book';
$font-family-sentinel-book-italic: 'Sentinel-BookItalic';
$font-family-sentinel-light: 'Sentinel-Light';
$font-family-sentinel-light-italic: 'Sentinel-LightItalic';

$font-family-serif: $font-family-sentinel-light, Georgia, "Times New Roman", Times, serif !default;
$font-family-base: $font-family-serif !default;

$font-size-xxxlg:                2rem;      // 32px
$font-size-xxlg:                 1.75rem;   // 28px
$font-size-xlg:                  1.38rem;   // 22px
$font-size-md:                   1.125rem;  // 18px
$font-size-xxs:                  .63rem;    // 10px

// Buttons

$btn-secondary-color:            $white;
$btn-secondary-bg:               $gray-light;
$btn-secondary-border:           $btn-secondary-bg;

$btn-secondary-dark-color:       $white;
$btn-secondary-dark-bg:          $gray-dark;
$btn-secondary-dark-border:      $btn-secondary-dark-bg;

$btn-dark-color:                 $white;
$btn-dark-subtitle-color:        $gray-light;
$btn-dark-bg:                    $black;
$btn-dark-border:                $btn-dark-bg;

$btn-success-bg:                 $brand-success;
$btn-success-border:             $btn-success-bg;

$btn-decision-color:             $black;
$btn-decision-subtitle-color:    $gray-light;
$btn-decision-bg:                $brand-decision;
$btn-decision-border:            $btn-decision-bg;

$btn-padding-x-md:               1rem;
$btn-padding-y-md:               .5rem;

$btn-border-radius-md:           0;
$btn-border-radius-sm:           0;

// z-index

$zindex-site-menu:                    1990 !default;
$zindex-site-menu-overlay-backdrop:   1980 !default;

// Navbars

$navbar-dark-color:              rgba(255, 255, 255, 1);
$navbar-dark-active-color:       rgba(000, 000, 000, 1);
$navbar-dark-color-bg:           $navbar-dark-color;
$nav-tabs-bg-color:              $navbar-dark-active-color;

// Jumbotron

$jumbotron-padding:              6rem !default;
$jumbotron-bg:                   $transparent !default;

// Tooltips

$tooltip-color:                  $black;
$tooltip-bg:                     $white;

// Forms

$input-border-radius:            0 !default;
$input-border-radius-lg:         0 !default;
$input-border-radius-sm:         0 !default;
$custom-control-indicator-bg:                 $white !default;
$custom-control-checked-indicator-color:      $black !default;
$custom-control-checked-indicator-bg:         $white !default;
$custom-checkbox-radius: 0 !default;
$custom-select-indicator:     url('../../svg/chevron-down.svg') !default;
$custom-select-border-radius: 0 !default;

// Breadcrumbs

$breadcrumb-padding-x:           0 !default;
$breadcrumb-bg:                  $transparent !default;

// Form states and Alerts
$state-success-text:             $brand-success !default;
$state-success-bg:               #ecf9ef !default;

$state-danger-text:              $brand-danger !default;
$state-danger-bg:                #eacccc !default;

$state-confirmation-bg:          $sand !default;
$state-confirmation-text:        $black !default;
$state-confirmation-border:      darken($state-confirmation-bg, 7%) !default;

// Alerts
$alert-confirmation-bg:          $state-confirmation-bg !default;
$alert-confirmation-text:        $state-confirmation-text !default;
$alert-confirmation-border:      $state-confirmation-border !default;

// Breakpoints
// ________________________________________________________

$small-phone-start: 320px;
$medium-phone-start: 375px;
$large-phone-start: 414px;
$small-tablet-start: 600px;
$medium-tablet-start: 768px;
$small-desktop-start: 1024px;
$medium-desktop-start: 1360px;
$large-desktop-start: 1920px;

$small-phone-end: $medium-phone-start - 1px;
$medium-phone-end: $large-phone-start - 1px;
$large-phone-end: $small-tablet-start - 1px;
$small-tablet-end: $medium-tablet-start - 1px;
$medium-tablet-end: $small-desktop-start - 1px;
$small-desktop-end: $medium-desktop-start - 1px;
$medium-desktop-end: $large-desktop-start - 1px;

$phone-end: $large-phone-end;
$tablet-start: $small-tablet-start;
$tablet-end: $medium-tablet-end;
$desktop-start: $small-desktop-start;

///////////////////////////////////////////////////////////
// Navbar /////////////////////////////////////////////////
///////////////////////////////////////////////////////////

// Navigation

.navbar-brand {
  @include size(50px);
  margin-top: 10px;
  padding-bottom: 0;
  padding-top: 0;
}

.navbar-brand-name {
  color: $white;
  vertical-align: middle;
}

// Navbar toggle

.navbar-toggler {
  color: $white;
  padding: 0;
  vertical-align: middle;
}

// ToolTime Navbar

.tooltime-navbar {
  color: $navbar-dark-color;
  height: 70px;
  left: 0;
  padding: 0;
  transition: all .5s ease;

  .tooltime-button {
    @include size(70px);
    &:focus,
    &:hover {
      box-shadow: none;
    }
  }

  .title {
    display: table-cell;
    font-family: $font-family-benton-sans-comp-book;
    font-size: 32px;
    height: 70px;
    margin-bottom: 0;
    text-transform: uppercase;
    vertical-align: middle;
  }

  .sub-title {
    @include absolute(auto, $right: auto, $bottom: 0, $left: 70px);
    font-family: $font-family-sentinel-light;
    font-size: $font-size-sm;
  }

  .title,
  .sub-title {
    padding-left: 1rem;
  }

  .last-logged-in {
    bottom: 10px;
    font-family: $font-family-sentinel-light;
    font-size: $font-size-xs;
    position: absolute;
    right: 520px;
    text-align: right;
  }
}
